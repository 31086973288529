import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import Login from '../views/Login.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Login',
		component: Login
	},
	{
		path: '/auftrag',
		name: 'Upload',
		component: () =>
			import(/* webpackChunkName: "doctor" */ '../views/Upload.vue'),
		meta: {
			requiresAuth: true,
			role: 2
		}
	},
	{
		path: '/doctor',
		name: 'Doctor',
		component: () =>
			import(/* webpackChunkName: "doctor" */ '../views/Doctor.vue'),
		meta: {
			requiresAuth: true,
			role: 2
		}
	},
	{
		path: '/lab',
		name: 'Lab',
		component: () => import(/* webpackChunkName: "lab" */ '../views/Lab.vue'),
		meta: {
			requiresAuth: true,
			role: 1
		}
	},
	{
		path: '/settings',
		name: 'Settings',
		component: () =>
			import(/* webpackChunkName: "lab" */ '../views/Settings.vue'),
		meta: {
			requiresAuth: true,
			role: 4
		}
	},
	{ path: '*', redirect: '/' }
];

const router = new VueRouter({
	mode: 'history',
	linkActiveClass: 'active',
	linkExactActiveClass: 'active',
	routes
});

const roles = {
	//only labload admins
	0: ['admin-user', 'admin-admin'],
	//only labs
	1: ['lab-user', 'lab-admin'],
	//only doctors
	2: ['doctor-user', 'doctor-admin'],
	//only client admins
	3: ['lab-admin', 'doctor-admin'],
	//all
	4: ['lab-user', 'lab-admin', 'doctor-user', 'doctor-admin']
};

router.beforeEach((to, from, next) => {
	const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
	if (requiresAuth) {
		if (store.getters.isUserLoggedIn) {
			if (roles[to.meta.role].includes(store.getters.getUserRole)) {
				next();
			} else {
				next('/');
			}
		} else {
			next('/');
		}
	} else {
		if (to.path === '/' && store.getters.isUserLoggedIn) {
			const path =
				store.getters.getAppMode === 'lab'
					? '/lab'
					: store.getters.getAppMode === 'doctor'
					? '/doctor'
					: '/';
			next(path);
		}
		next();
	}
});

export default router;
