import '@babel/polyfill';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Unicon from 'vue-unicons/dist/vue-unicons-vue2.umd';
import axiosPlugin from './plugins/axios.plugin';
import titlePlugin from './plugins/title.plugin';
import Vuelidate from 'vuelidate';
import VModal from 'vue-js-modal';
import VueEllipseProgress from 'vue-ellipse-progress';
import VDatePicker from 'v-calendar';
import {
	uniAngleDoubleLeft,
	uniAngleRight,
	uniInfoCircle,
	uniPlusCircle,
	uniTrashAlt,
	uniDownloadAltMonochrome,
	uniFileAlt,
	uniCheck,
	uniTimes,
	uniPen,
	uniUser,
	uniBag,
	uniAnalytics,
	uniSearch,
	uniTable,
	uniFavorite,
	uniFolder,
	uniCommentDots,
	uniUpload,
	uniImport,
	uniCopy
} from 'vue-unicons/dist/icons';

Unicon.add([
	uniAngleDoubleLeft,
	uniAngleRight,
	uniInfoCircle,
	uniPlusCircle,
	uniTrashAlt,
	uniDownloadAltMonochrome,
	uniFileAlt,
	uniCheck,
	uniTimes,
	uniPen,
	uniUser,
	uniBag,
	uniAnalytics,
	uniSearch,
	uniTable,
	uniFavorite,
	uniFolder,
	uniCommentDots,
	uniUpload,
	uniImport,
	uniCopy
]);
Vue.use(Unicon);

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(VModal);
Vue.use(axiosPlugin);
Vue.use(titlePlugin);
Vue.use(VueEllipseProgress);
Vue.use(VDatePicker, {
	componentPrefix: 'vc', // Use <vc-calendar /> instead of <v-calendar />
	locale: 'de',
	masks: {
		title: 'MMMM YYYY',
		weekdays: 'W',
		navMonths: 'MMM',
		input: ['L', 'YYYY-MM-DD', 'DD.MM.YYYY'],
		dayPopover: 'WWW, MMM D, YYYY',
		data: ['L', 'YYYY-MM-DD', 'DD.MM.YYYY']
	}
});

new Vue({
	router,
	store,
	render: (h) => h(App)
}).$mount('#app');
