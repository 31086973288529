import axios from 'axios';
import router from '../router';
import store from '../store';

let axiosPlugin = {};

axiosPlugin.install = function (Vue) {
	Vue.prototype.$api = axios.create({
		baseURL:
			process.env.NODE_ENV.trim() === 'development'
				? 'http://localhost:1234'
				: '/',
		withCredentials: true
	});
	Vue.prototype.$api.interceptors.response.use(
		function (res) {
			return res;
		},
		function (error) {
			if (
				(error.response.status === 403 || error.response.status === 401) &&
				router.currentRoute.name !== 'Login'
			) {
				store.dispatch('logout');
				router.replace('/');
			} else {
				return Promise.reject(error);
			}
		}
	);
};
export default axiosPlugin;
