<template>
	<nav>
		<div
			role="banner"
			class="navbar"
			:class="{ login: $route.name === 'Login' }"
		>
			<router-link
				class="logo"
				:to="
					$route.name === 'Login'
						? '/'
						: $store.getters.getAppMode === 'lab'
						? '/lab'
						: $store.getters.getAppMode === 'doctor'
						? '/doctor'
						: '/'
				"
				tag="a"
				><img :src="getLogo" loading="lazy" alt="" class="image"
			/></router-link>
			<div class="navigation">
				<nav role="navigation" class="nav-menu">
					<a
						href="https://www.labload.de"
						class="w-button button"
						v-if="showBackButton"
						>Zurück</a
					>
					<div class="mobile-button" v-else>
						<input type="checkbox" id="checkbox" />
						<label for="checkbox" class="mobile-button-lines">
							<span class="line"></span>
							<span class="line"></span>
							<span class="line"></span>
						</label>

						<ul v-if="!showBackButton">
							<li>
								<router-link tag="a" class="nav-link" to="doctor" v-if="!isLab"
									>Auftragsübersicht</router-link
								>
								<router-link tag="a" class="nav-link" to="lab" v-if="isLab"
									>Auftragsübersicht</router-link
								>
							</li>
							<li>
								<span class="nav-link" @click="$emit('openMessages')"
									>Nachrichten<span class="badge" v-if="unreadCount > 0">{{
										unreadCount
									}}</span></span
								>
							</li>
							<li>
								<router-link tag="a" class="nav-link" to="settings"
									>Einstellungen</router-link
								>
							</li>
							<li>
								<button class="nav-link" @click="logout">Abmelden</button>
							</li>
						</ul>
					</div>
				</nav>
			</div>
		</div>
	</nav>
</template>

<script>
export default {
	name: 'Header',
	data() {
		return {
			unreadCount: 0,
			getUnreadMessagesInterval: null
		};
	},
	computed: {
		showBackButton() {
			return this.$route.name === 'Login';
		},
		isAdmin() {
			let role = this.$store.getters.getUserRole.split('-');
			return role[1] === 'admin';
		},
		isClient() {
			let role = this.$store.getters.getUserRole.split('-');
			return role[1] === 'admin';
		},
		isLab() {
			let role = this.$store.getters.getUserRole.split('-');
			return role[0] === 'lab';
		},
		getLogo() {
			return 'https://storage.googleapis.com/uploadportal-logos/labload.png';
		}
	},
	watch: {
		'$route.name': function (name) {
			if (name !== 'Login' && this.$store.getters.isUserLoggedIn) {
				if (this.getUnreadMessagesInterval === null) {
					this.startUnreadCounterRequest();
				}
			} else {
				this.stopUnreadCounterRequest();
			}
		}
	},
	mounted() {
		if (this.$route.name !== 'Login' && this.$store.getters.isUserLoggedIn) {
			if (this.getUnreadMessagesInterval === null) {
				this.startUnreadCounterRequest();
			}
		} else {
			this.stopUnreadCounterRequest();
		}
	},
	beforeDestroy() {
		this.stopUnreadCounterRequest();
	},
	methods: {
		logout() {
			this.$store.dispatch('logout');
			this.stopUnreadCounterRequest();
			this.$router.replace('/');
		},
		startUnreadCounterRequest() {
			this.getUnreadMessagesInterval = setInterval(
				this.unreadCounterRequest,
				5000
			);
		},
		stopUnreadCounterRequest() {
			clearInterval(this.getUnreadMessagesInterval);
		},
		async unreadCounterRequest() {
			try {
				const response = await this.$api.post(
					'/messages/get_unread_count',
					{
						labUUID:
							this.$store.getters.getAppMode === 'lab'
								? this.$store.getters.getLab.id
								: null,
						doctorUUID:
							this.$store.getters.getAppMode === 'doctor'
								? this.$store.getters.getDoctor.id
								: null
					},
					{
						headers: {
							Authorization: `Bearer ${this.$store.getters.getUserToken}`
						}
					}
				);
				this.unreadCount = response.data.unread;
			} catch {
				this.stopUnreadCounterRequest();
			}
		}
	}
};
</script>
<style lang="scss">
.badge {
	display: inline-block;
	min-width: 10px;
	margin-left: 3px;
	padding: 5px 8px;
	font-size: 0.75rem;
	font-weight: 600;
	line-height: 1;
	color: $text-color;
	text-align: center;
	margin-top: -8px;
	vertical-align: middle;
	background-color: white;
	border-radius: 10px;
}
</style>
