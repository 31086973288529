<template>
	<section class="login-section">
		<div class="image-container">
			<img src="~@/assets/img/super.jpg" loading="lazy" class="image" />
		</div>
		<div class="login-form">
			<form @submit.prevent="login" class="login">
				<div class="input">
					<label for="username">Benutzername</label>
					<input
						type="email"
						id="username"
						v-model="$v.username.$model"
						autocomplete="username"
					/>
					<p class="has-error" v-if="$v.username.$error">
						Bitte Benutzername eingeben
					</p>
				</div>
				<div class="input">
					<label for="password">Passwort</label>
					<input
						type="password"
						id="password"
						v-model="$v.password.$model"
						autocomplete="current-password"
					/>
					<p class="has-error" v-if="$v.password.$error">
						Bitte Password eingeben
					</p>
				</div>
				<p class="has-error" v-if="loginError">
					Login nicht erfolgreich. Bitte überprüfen Sie Ihre Informationen
				</p>
				<button class="button" type="submit" :disabled="loading">Login</button>
			</form>
			<a href="https://www.labload.de" class="external-link"
				>Zurück zur Startseite</a
			>
		</div>
	</section>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import cryptoMixin from '@/mixins/crypto.mixin';
export default {
	name: 'Login',
	mixins: [cryptoMixin],
	data() {
		return {
			username: '',
			password: '',
			loginError: false,
			loading: false
		};
	},
	validations: {
		username: {
			required
		},
		password: {
			required
		}
	},
	mounted() {
		this.$setTitle('Login');
		this.$store.dispatch('clearAll');
	},
	methods: {
		async login() {
			this.$v.$touch();
			if (!this.$v.$anyError) {
				this.loginError = false;
				this.loading = true;
				try {
					const response = await this.$api.post('/auth/login', {
						email: this.username,
						password: this.password
					});
					this.$store.dispatch('login', {
						name: response.data.user.name,
						id: response.data.user.id,
						token: response.data.accessToken,
						role: response.data.user.role
					});
					switch (response.data.user.role.split('-')[0]) {
						case 'lab':
							this.loadLabData(response.data.accessToken);
							setTimeout(() => {
								this.$router.replace('/lab');
							}, 500);
							break;
							break;
						case 'doctor':
							this.loadDoctorData(response.data.accessToken);
							setTimeout(() => {
								this.$router.replace('/doctor');
							}, 500);
							break;
						case 'admin':
							this.$router.replace('/admin');
							break;
						default:
							this.loginError = true;
							break;
					}
				} catch (e) {
					this.loginError = true;
					this.loading = false;
				}
			}
		},
		async loadDoctorData(token) {
			const doctorData = await this.$api.post(
				'/auth/loadDoctorData',
				{},
				{
					headers: {
						Authorization: `Bearer ${token}`
					}
				}
			);

			this.$store.dispatch('registerAppSettings', {
				color: doctorData.data.color,
				logo: doctorData.data.logo,
				buttonColor: this.getButtonColor(doctorData.data.color),
				mode: 'doctor'
			});

			if (!doctorData.data.hasMultipleLabs) {
				this.$store.dispatch('registerLab', {
					id: doctorData.data.lab_id,
					name: doctorData.data.lab,
					schema: doctorData.data.schema,
					public_key: doctorData.data.lab_public_key
				});
			}

			if (doctorData.data.isClient) {
				this.$store.dispatch('registerAccount', {
					users: doctorData.data.users,
					account_id: doctorData.data.account_id
				});
			}

			if (doctorData.data.private_key === '') {
				const keys = await this.generatePublicPrivateKeyPair();
				const encryptedPrivateKey = await this.encryptPrivateKey(
					this.password,
					keys.privateKey
				);

				const setKeyResponse = await this.$api.post(
					'/auth/set_doc_keys',
					{
						public_key: keys.publicKey,
						private_key: encryptedPrivateKey,
						doctorUUID: doctorData.data.id
					},
					{
						headers: {
							Authorization: `Bearer ${token}`
						}
					}
				);

				if (setKeyResponse.status === 200) {
					this.$store.dispatch('registerDoctor', {
						id: doctorData.data.id,
						name: doctorData.data.doctor_name,
						public_key: keys.publicKey,
						hasMultipleLabs: doctorData.data.hasMultipleLabs
					});
					await this.$store.dispatch('setPrivateKey', {
						privateKey: keys.privateKey
					});
				}
			} else {
				this.$store.dispatch('registerDoctor', {
					id: doctorData.data.id,
					name: doctorData.data.doctor_name,
					public_key: doctorData.data.doctor_public_key,
					hasMultipleLabs: doctorData.data.hasMultipleLabs
				});

				const decryptedPrivateKey = await this.decryptPrivateKey(
					this.password,
					doctorData.data.private_key
				);
				await this.$store.dispatch('setPrivateKey', {
					privateKey: decryptedPrivateKey
				});
			}
		},

		async loadLabData(token) {
			const labData = await this.$api.post(
				'/auth/loadLabData',
				{},
				{
					headers: {
						Authorization: `Bearer ${token}`
					}
				}
			);

			this.$store.dispatch('registerAppSettings', {
				color: labData.data.color,
				logo: labData.data.logo,
				buttonColor: this.getButtonColor(labData.data.color),
				mode: 'lab'
			});

			this.$store.dispatch('registerLab', {
				id: labData.data.id,
				name: labData.data.lab,
				schema: labData.data.schema,
				public_key: labData.data.lab_public_key
			});

			if (labData.data.isClient) {
				this.$store.dispatch('registerAccount', {
					users: labData.data.users,
					account_id: labData.data.account_id
				});
			}

			this.$store.dispatch('setOwlLabIntegration', {
				active: true,
				url: labData.data.owllab_url,
				port: labData.data.owllab_port
			});

			const decryptedPrivateKey = await this.decryptPrivateKey(
				this.password,
				labData.data.private_key
			);
			await this.$store.dispatch('setPrivateKey', {
				privateKey: decryptedPrivateKey
			});
		},

		getButtonColor(hex) {
			if (hex === null) {
				return '#f5f5f5';
			}
			if (hex.indexOf('#') === 0) {
				hex = hex.slice(1);
			}
			if (hex.length === 3) {
				hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
			}
			if (hex.length !== 6) {
				throw new Error('Invalid HEX color.');
			}
			var r = parseInt(hex.slice(0, 2), 16),
				g = parseInt(hex.slice(2, 4), 16),
				b = parseInt(hex.slice(4, 6), 16);
			// http://stackoverflow.com/a/3943023/112731
			return r * 0.299 + g * 0.587 + b * 0.114 > 95 ? '#023c40' : '#f5f5f5';
		}
	}
};
</script>
