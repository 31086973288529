import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';
const ls = new SecureLS({ isCompression: false });

const crypto = window.crypto || window.msCrypto;

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		user: {
			token: null,
			id: null,
			name: null,
			role: null
		},
		lab: {
			id: null,
			name: null,
			schema: null,
			public_key: null
		},
		doctor: {
			id: null,
			name: null,
			public_key: null,
			hasMultipleLabs: null
		},
		account: {
			users: null,
			accountId: null
		},
		app: {
			color: null,
			logo: null,
			buttonColor: null,
			mode: null
		},
		features: [
			{
				name: null,
				active: null,
				settings: null
			}
		],
		privateKey: null
	},
	plugins: [
		createPersistedState({
			storage: {
				getItem: (key) => ls.get(key),
				setItem: (key, value) => ls.set(key, value),
				removeItem: (key) => ls.remove(key)
			}
		})
	],
	mutations: {
		setUser(state, { name, id, token, role }) {
			state.user.token = token;
			state.user.id = id;
			state.user.name = name;
			state.user.role = role;
		},
		setLab(state, { id, name, schema, public_key }) {
			state.lab.id = id;
			state.lab.name = name;
			state.lab.schema = schema;
			state.lab.public_key = public_key;
		},
		setDoctor(state, { id, name, public_key, hasMultipleLabs }) {
			state.doctor.id = id;
			state.doctor.name = name;
			state.doctor.public_key = public_key;
			state.doctor.hasMultipleLabs = hasMultipleLabs;
		},
		setAppSettings(state, { color, logo, buttonColor, mode }) {
			state.app.color = color;
			state.app.logo = logo;
			state.app.buttonColor = buttonColor;
			state.app.mode = mode;
		},
		setAccount(state, { users, account_id }) {
			state.account.account_id = account_id;
			state.account.users = users;
		},
		setFeature(state, { name, active, settings }) {
			let idx = state.features.findIndex((f) => f.name === name);
			if (idx >= 0) {
				state.features[idx].active = active;
				state.features[idx].settings = settings;
			} else {
				state.features.push({ name: name, active: active, settings: settings });
			}
		},
		setOwlLabStatus(state, { status }) {
			let idx = state.features.findIndex((f) => f.name === 'OwlLab');
			if (idx >= 0) {
				state.features[idx].settings.online = status;
			}
		},
		updateAccountUserCount(state, { count }) {
			state.account.users = count;
		},
		updateLabName(state, { name }) {
			state.lab.name = name;
		},
		updateLogo(state, { logoPath }) {
			state.app.logo = logoPath;
		},
		updateColor(state, { color, buttonColor }) {
			state.app.color = color;
			state.app.buttonColor = buttonColor;
		},
		removeLab(state) {
			state.lab.id = null;
			state.lab.name = null;
			state.lab.public_key = null;
			state.lab.schema = null;
		},
		removeDoctor(state) {
			state.doctor.id = null;
			state.doctor.name = null;
			state.doctor.public_key = null;
			state.doctor.hasMultipleLabs = null;
		},
		removeAccount(state) {
			state.account.users = null;
			state.account.account_id = null;
			state.app.color = null;
			state.app.buttonColor = null;
			state.app.logo = null;
			state.app.mode = null;
		},
		removeUser(state) {
			state.user.token = null;
			state.user.id = null;
			state.user.name = null;
			state.user.role = null;
			state.privateKey = null;
		},
		removeFeatures(state) {
			state.features = [];
		},
		setPrivateKey(state, key) {
			state.privateKey = key;
		},
		updateSchema(state, schema) {
			state.lab.schema = schema;
		},
		setOwlLabIntegration(state, { name, active, settings }) {
			state.features = state.features.filter((f) => f.name !== name);
			state.features.push({ name, active, settings });
		}
	},
	getters: {
		getUserToken: (state) => {
			return state.user.token;
		},
		isUserLoggedIn: (state) => {
			return (
				state.user.token !== null &&
				state.user.id !== null &&
				state.user.name !== null
			);
		},
		getLab: (state) => {
			return state.lab;
		},
		getDoctor: (state) => {
			return state.doctor;
		},
		getOwlLab: (state) => {
			let idx = state.features.findIndex((f) => f.name === 'OwlLab');
			if (idx >= 0) {
				return state.features[idx];
			}
			return null;
		},
		getAppMode: (state) => {
			return state.app.mode;
		},
		getUsername: (state) => {
			return state.user.name;
		},
		getUserRole: (state) => {
			return state.user.role;
		},
		getPrivateKey: (state) => {
			return crypto.subtle.importKey(
				'jwk',
				JSON.parse(state.privateKey),
				{ name: 'RSA-OAEP', hash: 'SHA-256' },
				true,
				['decrypt']
			);
		},
		getPublicKey: (state) => (type) => {
			let key = null;
			switch (type) {
				case 'lab':
					key = state.lab.public_key;
					break;
				case 'doctor':
					key = state.doctor.public_key;
					break;
				default:
					return false;
			}
			const rawKey = JSON.parse(key);
			return crypto.subtle.importKey(
				'jwk',
				rawKey,
				{ name: 'RSA-OAEP', hash: 'SHA-256' },
				true,
				['encrypt']
			);
		},
		getUser: (state) => {
			return state.user;
		},

		getLabID: (state) => {
			return state.lab.id;
		},
		isOwlLabOnline: (state) => {
			let idx = state.features.findIndex((f) => f.name === 'OwlLab');
			if (idx >= 0) {
				return state.features[idx].settings.online;
			}
			return false;
		},
		isClient: (state) => {
			return state.account.account_id !== null;
		},
		getAccount: (state) => {
			return state.account;
		}
	},
	actions: {
		login(context, { name, id, token, role }) {
			context.commit('setUser', { name, id, token, role });
		},
		registerLab(context, { id, name, schema, public_key }) {
			context.commit('setLab', {
				id,
				name,
				schema,
				public_key
			});
		},
		registerDoctor(context, { id, name, public_key, hasMultipleLabs }) {
			context.commit('setDoctor', {
				id,
				name,
				public_key,
				hasMultipleLabs
			});
		},
		registerAppSettings(context, { color, logo, buttonColor, mode }) {
			context.commit('setAppSettings', {
				color,
				logo,
				buttonColor,
				mode
			});
		},
		registerAccount(context, { users, account_id }) {
			context.commit('setAccount', {
				users,
				account_id
			});
		},
		setOwlLabIntegration(context, { active, url, port }) {
			let owllabObject = {
				url: url,
				port: port,
				online: false
			};
			context.commit('setOwlLabIntegration', {
				name: 'OwlLab',
				active: active,
				settings: owllabObject
			});
		},
		updateLabName(context, { name }) {
			context.commit('updateLabName', { name });
		},
		updateUserCount(context, { count }) {
			context.commit('updateAccountUserCount', { count });
		},
		updateLogo(context, { logoPath }) {
			context.commit('updateLogo', { logoPath });
		},
		updateColor(context, { color, buttonColor }) {
			context.commit('updateColor', { color, buttonColor });
		},
		logout({ commit }) {
			commit('removeUser');
			commit('removeLab');
			commit('removeAccount');
			commit('removeDoctor');
			commit('removeFeatures');
		},
		setPrivateKey(context, { privateKey }) {
			context.commit('setPrivateKey', privateKey);
		},
		setOwlLabStatus(context, { status }) {
			context.commit('setOwlLabStatus', { status });
		},
		updateSchema(context, { schema }) {
			context.commit('updateSchema', schema);
		},
		clearAll() {
			ls.removeAll();
			ls.clear();
		}
	},
	modules: {}
});
