<template>
	<div id="app">
		<Header @openMessages="openMessages"></Header>
		<router-view @openMessages="openMessages" />
		<Footer></Footer>
		<transition name="slide-fade">
			<Messages
				v-if="showMessages"
				@close="showMessages = false"
				:recordUUID="messagesRecordUUID"
			></Messages>
		</transition>
	</div>
</template>
<script>
import Socket from '@/plugins/socket';
import Header from './components/Header';
import Footer from './components/Footer';
export default {
	name: 'App',
	data() {
		return {
			interval: null,
			showMessages: false,
			messagesRecordUUID: null
		};
	},
	components: {
		Header,
		Footer,
		Messages: () => import('@/components/Messages.vue')
	},
	watch: {
		'$route.name': function (name) {
			if (name !== 'Login' && this.$store.getters.isUserLoggedIn) {
				if (this.interval === null) {
					this.startHeartBeat();
				}
			} else {
				this.stopHeartBeat();
				this.showMessages = false;
			}
		},
		showMessages: function (value) {
			if (!value) {
				this.messagesRecordUUID = null;
			}
		}
	},
	mounted() {
		if (this.$route.name !== 'Login' && this.$store.getters.isUserLoggedIn) {
			if (this.interval === null) {
				this.startHeartBeat();
			}
		} else {
			this.stopHeartBeat();
		}
	},
	updated() {
		if (this.$route.name !== 'Login' && this.$store.getters.isUserLoggedIn) {
			if (this.interval === null) {
				this.startHeartBeat();
			}
		} else {
			this.stopHeartBeat();
		}
	},
	methods: {
		startHeartBeat() {
			this.interval = setInterval(this.heartBeat, 120000);
		},
		stopHeartBeat() {
			Socket.closeSocket();
			clearInterval(this.interval);
		},
		async heartBeat() {
			try {
				await this.$api.post(
					'/auth/heartbeat',
					{},
					{
						headers: {
							Authorization: `Bearer ${this.$store.getters.getUserToken}`
						}
					}
				);
			} catch {
				this.stopHeartBeat();
				this.$store.dispatch('logout');
				this.$router.replace('/');
			}
		},
		openMessages(uuid) {
			this.showMessages = !this.showMessages;
			if (uuid !== null) {
				this.messagesRecordUUID = uuid;
			}
		}
	}
};
</script>
<style lang="scss">
.slide-fade-enter-active {
	transition: all 0.4s ease;
}
.slide-fade-leave-active {
	transition: all 0.3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
	transform: translateY(10px);
	opacity: 0;
}
</style>
