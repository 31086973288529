import aes from 'crypto-js/aes';
import ofb from 'crypto-js/mode-ofb';
import utf8 from 'crypto-js/enc-utf8';
export default {
	data() {
		return {
			crypto: null
		};
	},
	created() {
		this.crypto = window.crypto || window.msCrypto;
	},
	methods: {
		async generateAESKey() {
			let key = await this.crypto.subtle.generateKey(
				{
					name: 'AES-GCM',
					length: 128
				},
				true,
				['encrypt', 'decrypt']
			);
			let iv = this.crypto.getRandomValues(new Uint8Array(12));
			return { key: key, iv: iv };
		},
		async generatePublicPrivateKeyPair() {
			let keyPair = await this.crypto.subtle.generateKey(
				{
					name: 'RSA-OAEP',
					modulusLength: 4096,
					publicExponent: new Uint8Array([1, 0, 1]),
					hash: 'SHA-256'
				},
				true,
				['encrypt', 'decrypt']
			);
			let exportedPublicKey = await window.crypto.subtle.exportKey(
				'jwk',
				keyPair.publicKey
			);
			return {
				privateKey: keyPair.privateKey,
				publicKey: JSON.stringify(exportedPublicKey)
			};
		},
		async encryptFileAES(file, key, iv) {
			const encrypted = await this.crypto.subtle.encrypt(
				{
					name: 'AES-GCM',
					iv: iv
				},
				key,
				file
			);
			return encrypted;
		},
		async decryptFileAES(iv, data, key) {
			let decrypted = await this.crypto.subtle.decrypt(
				{
					name: 'AES-GCM',
					iv: iv
				},
				key,
				data
			);
			return decrypted;
		},
		async encryptAESKey(aesKey, publicKey) {
			let exported = await this.crypto.subtle.exportKey('raw', aesKey);
			let encryptedAESKey = await this.crypto.subtle.encrypt(
				{
					name: 'RSA-OAEP'
				},
				publicKey,
				exported
			);
			return encryptedAESKey;
		},
		async decryptAESKey(aesKey, privateKey) {
			let decryptedAESKey = await this.crypto.subtle.decrypt(
				{
					name: 'RSA-OAEP'
				},
				privateKey,
				aesKey
			);
			const key = await this.crypto.subtle.importKey(
				'raw',
				decryptedAESKey,
				{
					name: 'AES-GCM',
					length: 128
				},
				true,
				['encrypt', 'decrypt']
			);
			return key;
		},
		async decryptPrivateKey(password, encryptedPrivateKey) {
			let decrypted = aes.decrypt(encryptedPrivateKey, password, {
				mode: ofb
			});
			return decrypted.toString(utf8);
		},
		async parsePublicKey(rawKey) {
			const key = JSON.parse(rawKey);
			const parsed = await this.crypto.subtle.importKey(
				'jwk',
				key,
				{ name: 'RSA-OAEP', hash: 'SHA-256' },
				true,
				['encrypt']
			);
			return parsed;
		},
		async parsePrivateKey(rawKey) {
			const key = JSON.parse(rawKey);
			const parsed = await this.crypto.subtle.importKey(
				'jwk',
				key,
				{ name: 'RSA-OAEP', hash: 'SHA-256' },
				true,
				['decrypt']
			);
			return parsed;
		},
		async exportPrivateKey(key) {
			const parsed = await this.crypto.subtle.exportKey('jwk', key);
			return JSON.stringify(parsed);
		},
		async encryptPrivateKey(newPassword, priv) {
			const privateKey = await this.exportPrivateKey(priv);
			let encrypted = aes.encrypt(privateKey, newPassword, {
				mode: ofb
			});
			return encrypted.toString();
		},
		str2ab(str) {
			let buf = new ArrayBuffer(str.length); // 2 bytes for each char
			let bufView = new Uint8Array(buf);
			for (let i = 0, strLen = str.length; i < strLen; i++) {
				bufView[i] = str.charCodeAt(i);
			}
			bufView = null;
			return buf;
		},
		ab2str(buf) {
			const uint8Array = new Uint8Array(buf);
			const data = uint8Array.reduce(
				(acc, i) => (acc += String.fromCharCode.apply(null, [i])),
				''
			);
			return data;
		},
		readFileAsync(file) {
			return new Promise((resolve, reject) => {
				let reader = new FileReader();

				reader.onload = () => {
					resolve(reader.result);
				};

				reader.onerror = reject;

				reader.readAsArrayBuffer(file);
			});
		}
	}
};
