class Socket {
	socket = null;

	constructor() {}

	getSocket(host, port) {
		let url = `ws://${host}:${port}`;
		if (this.socket === null) {
			return this.createSocket(url);
		} else if (this.socket.url !== url + '/') {
			this.closeSocket();
			return this.createSocket(url);
		} else {
			return this.socket;
		}
	}

	checkSocket(host, port) {
		let url = `ws://${host}:${port}/`;
		return this.socket === null
			? false
			: this.socket.url === url && this.socket.readyState == WebSocket.OPEN;
	}

	createSocket(url) {
		this.socket = new WebSocket(url);
		this.socket.onopen = function (event) {
			console.info('WebSocket is open now!');
		};
		return this.socket;
	}

	closeSocket() {
		if (this.socket !== null) {
			this.socket.close(1000);
			this.socket = null;
		}
	}
}

export default new Socket();
