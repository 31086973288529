<template>
	<footer class="footer">
		<nav class="footer-nav">
			<ul>
				<li><a href="https://www.labload.de/#Kontakt">Kontakt</a></li>
				<li><a href="https://www.labload.de/impressum">Impressum</a></li>
				<li>
					<a href="https://www.labload.de/datenschutz">Datenschutzerklärung</a>
				</li>
				<li><a href="https://www.labload.de/agb">AGB</a></li>
			</ul>
		</nav>
		<img src="../assets/img/labload.png" loading="lazy" alt="" class="image" />
		<p class="copyright">&copy; {{ new Date().getFullYear() }} LabLoad</p>
		<a href="https://www.buildit-consulting.de" class="external-link"
			>by BuildIT Consulting</a
		>
	</footer>
</template>

<script>
export default {
	name: 'Footer',
	computed: {
		labName() {
			return this.$store.getters.getLab.name || 'LabLoad';
		}
	}
};
</script>
